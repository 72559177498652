import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';

const MenuIcon = () => (
  <Icon>
    <Link to="/search/">
      <SearchIcon aria-label="Search work orders" />
    </Link>
  </Icon>
);

export default MenuIcon;

const Icon = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  a {
    display: inline-block;
    padding: .66em 1.25em .33em;
  }
  svg {
    width: 2.625em;
    height: 2.625em;
    fill: #fff;
  }
`;
