/* eslint-disable quote-props */
import { mix } from 'polished';

const background = '#1a1a1a';
const primary = '#5f51b6';
const secondary = '#2aa591';
const positive = '#44b400';
const neutral = '#006edf';
const negative = '#ed0038';
const warning = '#da0';
const seminegative = mix(0.8, warning, negative);
const old = '#604d42';


// Define our `fg` and `bg` on the theme
const theme = {
  mq: { // media quiries
    bigmob: '24rem',
    medium: '38rem',
    large: '65rem',
    xlarge: '80rem',
    max: '92rem',
  },
  colours: { // main/basic colours
    background,
    primary,
    secondary,
    alert: negative, // add some extra mappings
    blue: neutral, // add some extra mappings
    positive,
    neutral,
    seminegative,
    negative,
    warning,
  },
  statusColours: { // event/status based colours
    // positive
    'approved': mix(0.8, neutral, negative),
    'ready': mix(0.85, neutral, negative),
    'started': mix(0.7, positive, neutral),
    'completed': mix(0.8, positive, neutral),
    'targetAchieved': mix(0.4, positive, neutral),
    /*'WorkOrderApproved': mix(0.6, positive, neutral),
    'WorkOrderApprovalRequested': mix(0.3, positive, neutral),
    'WorkOrderApprovalGranted': mix(0.9, positive, neutral),
    'WorkOrderCreated': mix(0.3, positive, neutral),
    'WorkOrderScheduled': mix(0.4, positive, neutral),
    'WorkOrderStarted': mix(0.7, positive, neutral),
    'WorkItemStarted': mix(0.4, positive, neutral),
    'WorkItemCompleted': mix(0.7, positive, neutral),
    'WorkBatchStarted': mix(0.7, positive, neutral),
    'WorkBatchCompleted': mix(0.9, positive, neutral),
    'WorkOrderReady': mix(0.3, positive, neutral),
    'WorkOrderSubmitted': mix(0.5, positive, neutral),
    'WorkOrderCompleted': mix(1, positive, neutral),
    'WorkBatchTargetQuantityAchieved': mix(1, positive, neutral),*/
    // in progress
    'added': mix(0.5, positive, neutral),
    'inProgress': mix(0.2, positive, neutral),
    'submitted': mix(0.2, positive, neutral),
    //'WorkBatchAdded': mix(0.5, positive, neutral),
    'neutral': neutral,
    // neutral // informative
    'scheduled': mix(0.9, neutral, negative),
    /*'WorkBatchQuantityRequiredChanged': mix(0.2, positive, neutral),
    'WorkBatchDueOnChanged': mix(0.2, positive, neutral),
    'ProcedurePublished': mix(0.5, positive, neutral),
    'ProcedureStepAdded': mix(0, positive, neutral),
    'ProcedureCreated': mix(0.3, positive, neutral),
    'ProductCreated': mix(0.7, positive, neutral),
    'WorkOrderTargetQuantityChanged': neutral,
    'WorkOrderDueOnChanged': neutral,
    'WorkBatchTargetQuantityChanged': neutral,*/
    // old
    'archived': old,
    // 'WorkOrderArchived': old,
    // negative / danger
    'draft': mix(0.65, neutral, negative),
    'approvalRequested': mix(0.7, neutral, negative),
    'awaitingResources': mix(0.75, neutral, negative),
    'rejected': mix(0, neutral, negative),
    'removed': mix(0.1, neutral, negative),
    // 'WorkOrderRejected': mix(0, neutral, negative),
    'cancelled': mix(0.8, warning, negative),
    /*'WorkOrderCancelled': mix(0.2, neutral, negative),
    'WorkItemCancelled': mix(0.4, neutral, negative),
    'WorkItemRejected': mix(0.2, neutral, negative),
    'WorkBatchCancelled': mix(0.2, neutral, negative),
    'WorkOrderDeleted': mix(0.1, neutral, negative),
    'WorkOrderUnscheduled': mix(0.4, neutral, negative),
    'WorkOrderApprovalRequestRetracted': mix(0.3, neutral, negative),
    'WorkOrderSubmissionWithdrawn': mix(0.3, neutral, negative),
    'WorkOrderResourcesAwaited': mix(0.1, neutral, negative),
    'WorkOrderApprovalRevoked': mix(0.3, neutral, negative),
    'WorkOrderApprovalRejected': mix(0.3, neutral, negative),
    'WorkBatchRemoved': mix(0.3, neutral, negative),*/
    //items
    'itemCancelled': mix(0.8, warning, negative),
    'itemRejected': mix(0.2, warning, negative),
  },
};

export default theme;
