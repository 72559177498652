import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const rootPath = window.location.origin;

const config = {
  auth: {
    authority: process.env.REACT_APP_AZUREAD_AUTHORITY,
    clientId: process.env.REACT_APP_AZUREAD_CLIENTID,
    redirectUri: rootPath,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

const authenticationParameters = {
  scopes: [
    'api://vas/.default',
  ],
};

const options = {
  loginType: LoginType.Redirect,
  //tokenRefreshUri: `${window.location.origin}/auth.html`,
};

const authProvider = new MsalAuthProvider(config, authenticationParameters, options);

export default authProvider;

/* export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: process.env.REACT_APP_AZUREAD_AUTHORITY,
      clientId: process.env.REACT_APP_AZUREAD_CLIENTID,
      redirectUri: rootPath,
      navigateToLoginRequestUrl: true,

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
  },
  {
    scopes: [
      'openid',
      'profile',
      'api://vas/.default',
    ],
  },
  LoginType.Popup,
);*/