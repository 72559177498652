import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MenuIcon = ({ toggleNav }) => (
  <Icon onClick={toggleNav} aria-label="Toggle VAS Manager navigation">
    <IconLine />
    <IconLine />
    <IconLine />
  </Icon>
);

export default MenuIcon;

MenuIcon.defaultProps = {
  toggleNav: null,
};

MenuIcon.propTypes = {
  toggleNav: PropTypes.func,
};

const Icon = styled.button`
  width: 3.75rem;
  padding: .75rem 1.25rem;
  margin-right: -1.5rem;
  border: none;
  cursor: pointer;
  background-color: transparent;
`;

const IconLine = styled.div`
  width: 100%;
  height: 3px;
  margin: 4px 0;
  background: #fff;
`;
